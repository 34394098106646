import BaseGridExtension from 'o365.controls.Grid.BaseExtension.ts';

declare module 'o365.controls.Grid.BaseGrid.ts' {
    interface BaseGridControl {
        arrayData: GridArrayData;
    }
}

/** Implementation of grid functionality for array data */
export default class GridArrayData extends BaseGridExtension {
    static __extension = 'arrayData';

    /** Data array with the fikter and sort applied */
    data: any[];
    /** Current data array index */
    currentIndex: number;
    /** Current item getter */
    get current() { return this.data[this.currentIndex] ?? {}; }

    rowCount: number;
    filteredRowCount: number;

    private _applySort: Function;
    /** Apply current sort */
    applySort() { if (this.applySort) { this._applySort(); } }

    initializeExtension() {
        this.data = [];
    };

    get dataLength() {
        return this.filteredRowCount != null
            ? this.filteredRowCount
            : this.rowCount ?? 0;
    }

    /** Set current array data index */
    setCurrentIndex(newIndex: number) {
        this.data.forEach(item => { if (item.current) { item.current = false; } })

        this.currentIndex = newIndex;

        if (this.currentIndex != null && this.data[this.currentIndex]) {
            this.data[this.currentIndex].current = true;
        }
    }
}